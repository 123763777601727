import React, { Component } from 'react';
import Helmet from 'react-helmet';

class TabClockTab extends Component {

    state = {
        'currSeo': this.props.seoLinks.filter((seo) => seo.path === this.props.location.pathname).length !== 0 ?
            this.props.seoLinks.filter((seo) => seo.path === this.props.location.pathname)[0] :
            this.props.seoLinks[0]
    }

    componentDidMount() {
        let t1 = setTimeout(() => document.getElementById('wait').innerText = 'connecting...', 1500);
        let t2 = setTimeout(() => document.getElementById('wait').innerText = 'loading...', 2700);
        let t3 = setTimeout(() => document.getElementById('wait').innerText = 'wait...', 3800);
        let tDef = setTimeout(() => window.location = 'https://timenowin.net', 6000); // max to wait

        // check when ads as printed to
        var observer = new MutationObserver(function (mutations) {
            if (document.getElementById('p_6575265')) {
                //console.log("It's in the DOM!");
                clearTimeout(t1);
                clearTimeout(t2);
                clearTimeout(t3);
                clearTimeout(tDef);
                setTimeout(() => document.getElementById('wait').innerText = 'wait.', 1500);
                setTimeout(() => document.getElementById('wait').innerText = 'wait...', 2600);
                setTimeout(() => document.getElementById('wait').innerText = 'wait.....', 4500);
                setTimeout(() => document.getElementById('wait').innerText = 'wait........', 6500);
                setTimeout(() => document.getElementById('wait').innerText = 'wait...........', 7500);
                setTimeout(() => window.location = 'https://timenowin.net', 7500);
                observer.disconnect();
            }
        });
        observer.observe(document, { attributes: false, childList: true, characterData: false, subtree: true });
    }

    render() {
        const ldJson = {
            "@context": "https://schema.org",
            "@type": "WebApplication",
            "name": "Tab Clock Tab: Full Screen - Digital/Analog - Night mode",
            "applicationCategory": "BrowserApplication",
            "browserRequirements": "Requires JavaScript. Requires HTML5.",
            "operatingSystem": "All",
            "offers": {
                "@type": "Offer",
                "price": "0",
                "priceCurrency": "USD"
            },
            "url": "https://www.tabclocktab.com",
            "keywords": "tab clock tab,clock,digital clock,analog clock",
            "description": "Tab Clock Tab - exact time with seconds on the full screen. Night mode, analogue or digital view switch."
        }


        ldJson.name = this.state.currSeo.title ? this.state.currSeo.title : "Tab Clock Tab: Full Screen - Digital/Analog - Night mode"
        ldJson.description = this.state.currSeo.description ? this.state.currSeo.description : "Tab Clock Tab - exact time with seconds on the full screen. Night mode, analogue or digital view switch."
        ldJson.url = 'https://www.tabclocktab.com' + this.props.location.pathname;

        return (
            <React.Fragment>
                <Helmet>
                    <html lang={this.props.location.pathname.split('/')[2] ? this.props.location.pathname.split('/')[2] : 'en'} />
                    <title>{ldJson.name}</title>
                    <meta name="description" content={ldJson.description} />
                    <meta property="og:description" content={ldJson.description} />
                    <meta property="og:image" content="https://www.tabclocktab.com/clock.png" />
                    <meta name="author" content="tab clock tab" />
                    <link rel="icon" href="/favicon.ico" />
                    <meta name="keywords" content={ldJson.keywords} />
                    {/* <meta name="monetag" content="d944b72def7decc45de08033ecbf5946"></meta> */}
                    <script async="async" data-cfasync="false" src="//ophoacit.com/1?z=6575265"></script>
                    <link rel="preconnect" href="https://ophoacit.com" />
                    <link rel="dns-prefetch" href="https://ophoacit.com" />
                    <link rel="preload" href="https://www.googletagmanager.com/gtag/js?id=G-KNP825DZMX" as="script" />
                    <link rel="preload" href="https://ophoacit.com/1?z=6575265" as="script" />    
                    <link rel="canonical" href={ldJson.url} />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-KNP825DZMX"></script>
                    <script>
                        {`var host = window.location.hostname;
                        if (host != "localhost") {
                            window.dataLayer = window.dataLayer || [];
                            function gtag() { dataLayer.push(arguments); }
                            gtag('js', new Date());
                            gtag('config', 'G-KNP825DZMX');
                        }`}
                    </script>
                </Helmet>
                <img src="/spinner.gif" alt="loading" width="20" height="20" /><div id="wait" style={{ 'color': '#000' }}></div>
                <ul>
                    {this.props.seoLinks.filter(seo => seo.domain === 'tabclocktab.com').map((seo, idx) =>
                        <li key={idx}><a href={`${seo.path}`} title={seo.anchorText}>{seo.anchorText}</a></li>)}
                </ul>

                <h1>{this.state.currSeo.anchorText}</h1>
                <h2>Displays the current time on the tab</h2>
                <p>{`It's always like this, when we need something we never have one nearby, which ends up being very frustrating at times. Who doesn't need or need a ${this.state.currSeo.anchorText} at some point?!`}<br />
                    {`Wow... Now it exists! ${this.state.currSeo.anchorText}`}<br />{`
                    The best and most complete ${this.state.currSeo.anchorText} is simple, fast and easy to use, always available when you need it.
                `}</p>
            </React.Fragment>
        )
    }


}

export default TabClockTab;